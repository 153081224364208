import { SplitBtnInputValType } from "@/components/common/split-button/SplitButton";
import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Limit_Account_Balance_Configuration
interface ProductCadencesFormProps {
  ifxAcctType: string;
  stmtFreq: string;
  stmtFreqMatrix: string;
  interimStmtFreq: string;
  interimStatementFrequencyButton: SplitBtnInputValType;
  stmtStartDtmOpt: number;
}

const basicProductConfigurationSchema = async (optionsSchema: PostSchema) => {
  const fields = [
    "stmtFreq",
    "stmtFreqMatrix",
    "interimStmtFreq",
    "stmtStartDtmOpt",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

export type { ProductCadencesFormProps };
export { basicProductConfigurationSchema as getValidationSchema };
