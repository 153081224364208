"use client";

import { ProductCatalog, MyProducts } from "@/components";
import {
  Box,
  Button,
  CloseIcon,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@/components/ChakraUiManager";
import "./ProductLaunchPad.scss";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  CardContainer,
  ConfigurationStatusComponent,
  ProductTitle,
} from "../../common";
import { warningIcon } from "public/assets";

import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import {
  ENTITLEMENT_STATUS,
  SUMMARY_LANDING_SCREEN_STATUS,
  WORKFLOW_STATUS,
} from "@/utils/constants";
import Image from "next/image";

import { ROUTE_BASE_URL } from "@/route-config/route-path";
import Link from "next/link";
import { getEntitlement, getEnvId, getEnvIsDisable, getProj } from "@/store";
import { useSelector } from "react-redux";
import { API_ROUTE_CONFIGURATION, ApiClient } from "@/api-config";
import { getCoreModelEndpoint } from "@/api-config/api-service";
import { OptionsSchema } from "../model/types";
import { ENTITLEMENT_MESSAGE } from "@/components/data/error-data";
import { ProductType } from "../model/product-models";
import { fetchProductList } from "../product-management-api-service";
import { filterProductsWithWorkflowStatus } from "../product-management-util-service";
import AppBodyGridWithHeader from "@/components/layout/AppBodyGridWithHeader/AppBodyGridWithHeader";
import { ErrorAccess } from "@finxact/finxact-shared-ui";

interface ProductLaunchPadProps {
  isPreRequisiteUnmet: boolean;
}

export default function ProductLaunchPad(props: ProductLaunchPadProps) {
  const { isPreRequisiteUnmet } = props;

  //context API
  const { resetProductInfo } = useContext<ProductConfigurationContextType>(
    productConfigurationContext
  );
  const [tabIndex, setTabIndex] = useState<number>(isPreRequisiteUnmet ? 0 : 1);
  const [productList, setProductList] = useState<ProductType[]>([]);
  const [closeWarning, setCloseWarning] = useState<boolean>(false);
  const isEnvDisabled = useSelector(getEnvIsDisable);
  const envId = useSelector(getEnvId);
  const proj = useSelector(getProj);
  const toast = useToast();
  const toastId = "product-list-entitlement";

  const [isAccleratorSupported, setAcceleratorConfig] =
    useState<boolean>(false);
  const entitlement = useSelector(getEntitlement);
  const isAccessRestricted =
    entitlement?.pc_dep === ENTITLEMENT_STATUS.noAccess &&
    entitlement?.pc_loan === ENTITLEMENT_STATUS.noAccess;
  const data = {
    envId,
    entitlement: isAccessRestricted,
    projId: proj?.id,
  };
  // compute tab index when isPreReqUnmet state changes
  useEffect(() => {
    setTabIndex(isPreRequisiteUnmet ? 0 : 1);
  }, [isPreRequisiteUnmet]);

  const [shouldRender, setRenderState] = useState(false);

  async function getProducts() {
    const api = new ApiClient({ baseUrl: getCoreModelEndpoint() });
    await Promise.all([
      fetchProductList(),
      api.options(API_ROUTE_CONFIGURATION.product) as Promise<OptionsSchema>,
    ]).then((res) => {
      if (res[0].length) {
        setProductList(res[0]);
      }
      if (res[1]?.actions.POST.properties.hasOwnProperty("isAccelerator__")) {
        setAcceleratorConfig(true);
      }
    });
  }

  const completedProducts = useMemo(() => {
    return filterProductsWithWorkflowStatus(productList).filter(
      (pdt) => pdt.status === WORKFLOW_STATUS.completed
    );
  }, [productList]);

  useEffect(() => {
    if (!productList || productList.length === 0) {
      getProducts();
    }
    resetProductInfo?.();
  }, [productList]);

  useEffect(() => {
    const hasCompleted = completedProducts.length > 0;
    if (hasCompleted) {
      setRenderState(true);
    }
  }, [completedProducts]);

  const nonAccelaratorProductList = useMemo(() => {
    return (
      productList?.filter((product) => {
        return isAccleratorSupported ? !Boolean(product.isAccelerator__) : true;
      }) ?? []
    );
  }, [isAccleratorSupported, productList]);

  return (
    <AppBodyGridWithHeader>
      <ProductTitle
        title={"Product Launchpad"}
        subTitle={
          "Review your saved products or discover templated solutions crafted to simplify your experience."
        }
        titleAbove
        align="left"
      />
      <Box className={`product-launchpad-container`}>
        {(envId || proj?.id) &&
          !isAccessRestricted &&
          isPreRequisiteUnmet &&
          !closeWarning && (
            <CardContainer customClass="toast-conatiner">
              <Box className="toast-icon">
                <Image src={warningIcon} alt="warning" />
              </Box>
              <Box className="toast-text">
                <Heading as="h5">Outstanding pre-requisites</Heading>
                <Text>Complete your Foundational Setup to proceed below.</Text>
              </Box>
              <Box className="toast-link">
                <Link href={ROUTE_BASE_URL["DASHBOARD_PATH"]}>
                  <Button className="app-btn-link">Update</Button>
                </Link>
              </Box>
              <Box className="toast-close">
                <CloseIcon
                  onClick={() => setCloseWarning(true)}
                  className="close-icon"
                />
              </Box>
            </CardContainer>
          )}
        {/* Display error screen if not having entitlement to view the page */}
        {!(envId || proj?.id) || isAccessRestricted ? (
          <ErrorAccess data={data} />
        ) : (
          envId &&
          !isAccessRestricted &&
          shouldRender && (
            <>
              <Box mt={4} mb={16}>
                <ConfigurationStatusComponent
                  title="PRODUCT CONFIGURATION"
                  status={
                    isPreRequisiteUnmet
                      ? SUMMARY_LANDING_SCREEN_STATUS.unmet
                      : completedProducts.length > 0
                        ? SUMMARY_LANDING_SCREEN_STATUS.prod_completed
                        : SUMMARY_LANDING_SCREEN_STATUS.in_progress
                  }
                  leftBadgeLabel="FOUNDATIONAL SETUP"
                  leftBadgeStatus={
                    isPreRequisiteUnmet
                      ? SUMMARY_LANDING_SCREEN_STATUS.in_progress
                      : SUMMARY_LANDING_SCREEN_STATUS.completed
                  }
                  rightBadgeLabel="TEST & DEPLOY"
                  rightBadgeStatus={
                    isPreRequisiteUnmet
                      ? SUMMARY_LANDING_SCREEN_STATUS.not_started
                      : completedProducts.length > 0
                        ? SUMMARY_LANDING_SCREEN_STATUS.completed
                        : SUMMARY_LANDING_SCREEN_STATUS.in_progress
                  }
                />
              </Box>
              <Tabs index={tabIndex} isManual>
                <TabList mb="3.8rem">
                  <Tab onClick={() => setTabIndex(0)}>Product Catalog</Tab>
                  <Tab onClick={() => setTabIndex(1)}>My Products</Tab>
                </TabList>
                <TabPanels>
                  {/* initially mounted */}
                  <TabPanel>
                    <Box className="product-lauchpad-catalog">
                      <ProductCatalog
                        productList={productList}
                        isEnvDisabled={isPreRequisiteUnmet || isEnvDisabled}
                        isAccleratorSupported={isAccleratorSupported}
                      />
                    </Box>
                  </TabPanel>
                  {/* initially not mounted */}
                  <TabPanel>
                    <Box className="product-lauchpad-myproducts">
                      <MyProducts
                        setTabIndex={setTabIndex}
                        productList={nonAccelaratorProductList}
                        isPreRequisiteUnmet={isPreRequisiteUnmet}
                        isEnvDisabled={isEnvDisabled}
                        isAccleratorSupported={isAccleratorSupported}
                      />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )
        )}
      </Box>
    </AppBodyGridWithHeader>
  );
}
