"use client";
import { Box, Button, useToast } from "@/components/ChakraUiManager";
import {
  AccordionComponent,
  AppFormLabel,
  AppNumberInput,
  CardContainer,
  CoupleRadioWithDropdown,
  DurationInput,
  ErrorAlert,
  FrequencyModule,
  InputText,
  StaticText,
} from "@/components/common";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  AdditionalProductFormProps,
  getValidationSchema,
} from "./AdditionalProductFormValidation";

import { updateProduct, updateWorkflowGeneric } from "@/api-config/api-service";
import { ProductType } from "@/components/product-management/model/product-models";
import {
  ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS,
  DASHBOARD_ACCORDION_STATUS,
  IFX_PRODUCT_TYPE_VAL,
} from "@/utils/constants";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import {
  OptionsSchema,
  PostSchema,
} from "@/components/product-management/model/types";
import { isProductTypeIsLoan } from "@/components/product-management/product-management-util-service";
import { useUnsavedChanges } from "@/components/context-api/unsaved-changes-provider/UnsavedChangesProvider";
import { useProductTypeCheck } from "@/utils/common";
import {
  exceptRuleUiSchema,
  feeCalcDtlUnRequire,
  getFeeCalcDtlUiSchema,
} from "@finxact/finxact-shared-ui";
import { RjsfData } from "@/utils/types";
import { addNewComponent } from "../../product-config-client-service";

type Props = {
  data: {
    formData: ProductType;
    prodBkOptionsData: PostSchema;
    feeOptions: string[];
    restrictionCodes: string[];
    addNewSchema: OptionsSchema;
    addNewOptionEndpoint: string;
    addNewExceptRulesSchema: OptionsSchema;
    addNewOptionExceptRules: string;
  };
};

const initialServiceConfig = {
  svcName: "",
};

const AdditionalProductForm = (props: Props) => {
  const toast = useToast();
  //context API
  const {
    isGoingBack,
    canCheckFormStatus,
    productDetails,
    navigateToNextPage,
    updateFormStatus,
    updateStatusInSideBarMenuList,
    getExistingProduct,
    tooltipFlyoutDetails,
    getWorflowStatusToBeUpdated,
    configPageTitle,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const {
    formData,
    restrictionCodes,
    feeOptions,
    addNewSchema,
    addNewOptionEndpoint,
    addNewExceptRulesSchema,
    addNewOptionExceptRules,
  } = props.data;

  const [validationSchema, setValidationSchema] = useState<any>(null);

  // Store validation schema
  useEffect(() => {
    (async () => {
      const yupSchema = await getValidationSchema(props.data.prodBkOptionsData);
      setValidationSchema(yupSchema);
    })();
  }, [props.data.prodBkOptionsData]);

  const onSubmit = async (values: AdditionalProductFormProps, actions: any) => {
    if (!dirty) {
      navigateToNextPage?.();
      getWorflowStatusToBeUpdated?.() &&
        updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails?.name ?? "",
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["additionalProductFeatures"],
          status: DASHBOARD_ACCORDION_STATUS.completed,
        });
      return;
    }

    if (formData) {
      const payload = {
        fundTerm: values.fundTerm,
        zeroBalAlertDays: parseInt(values.zeroBalAlertDays),
        zeroBalCloseDays: parseInt(values.zeroBalCloseDays),
        apy: parseFloat(values.apy),
        svcs: values?.svcs?.length
          ? values?.svcs.filter((item) => item.svcName)
          : [],
        // Inactive
        inactiveStatusDur: values.inactiveStatusDur,
        inactiveStatusAlertFreq: values.inactiveStatusAlertFreq ?? "",
        inactiveStatusFee: values.inactiveStatusFee,
        inactiveStatusRestrictCode: values.inactiveStatusRestrictCode,

        // Dormant
        dormantStatusAlertFreq: values.dormantStatusAlertFreq ?? "",
        dormantStatusDur: values.dormantStatusDur,
        dormantStatusFee:
          values.dormantStatusFee === "" ? null : values.dormantStatusFee,
        dormantStatusRestrictCode:
          values.dormantStatusRestrictCode === ""
            ? null
            : values.dormantStatusRestrictCode,
      };
      const res = await updateProduct({
        productName: formData.name,
        body: JSON.stringify({
          // NOTE: _vn, componentName, version is required to do a PATCH update
          _vn: formData?._vn,
          // PATCH fields
          ...payload,
        } as ProductType),
      });

      if (res && productDetails?.name) {
        if (!toast.isActive("additional-product-components")) {
          toast({
            id: "additional-product-components",
            description: `${configPageTitle} updated.`,
            status: "success",
          });
        }

        getExistingProduct?.(productDetails.name, true);
        await updateWorkflowGeneric({
          model: API_ROUTE_CONFIGURATION.product,
          key: productDetails.name,
          stage:
            ADDITIONAL_PRODUCTS_ATTRIBUTE_CLASS["additionalProductFeatures"],
          status: DASHBOARD_ACCORDION_STATUS.completed,
        });
      }
    }
  };

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      fundTerm: formData?.fundTerm ?? "",
      zeroBalAlertDays: formData?.zeroBalAlertDays?.toString() ?? "",
      zeroBalCloseDays: formData?.zeroBalCloseDays?.toString() ?? "",
      apy: formData?.apy?.toString() ?? "",
      svcs: formData?.svcs?.length ? formData.svcs : [],

      // Inactive
      inactiveStatusDur: formData?.inactiveStatusDur ?? "",
      inactiveStatusAlertFreq: formData?.inactiveStatusAlertFreq ?? "",
      inactiveStatusFee: formData?.inactiveStatusFee ?? "",
      inactiveStatusRestrictCode: formData?.inactiveStatusRestrictCode ?? "",
      // Dormant
      dormantStatusAlertFreq: formData?.dormantStatusAlertFreq ?? "",
      dormantStatusDur: formData?.dormantStatusDur ?? "",
      dormantStatusFee: formData?.dormantStatusFee ?? "",
      dormantStatusRestrictCode: formData?.dormantStatusRestrictCode ?? "",
    },
  });
  const {
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    dirty,
    handleBlur,
  } = formik;

  const { setUnsavedChanges } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      setUnsavedChanges({ hasUnsavedChanges: true });
    }
  }, [dirty, setUnsavedChanges]);

  useEffect(() => {
    if (updateFormStatus) {
      updateFormStatus("INPROGRESS");
    }
    if (updateStatusInSideBarMenuList) {
      updateStatusInSideBarMenuList("INPROGRESS");
    }
  }, []);

  useEffect(() => {
    if (navigateToNextPage) navigateToNextPage();
  }, [canCheckFormStatus]);

  // Handle form field changes
  const handleFormData = (key: string, index?: number) => (value: any) => {
    if (key === "svcName" && index !== undefined) {
      setFieldValue(`svcs.${index}.${key}`, value);
    }
    setFieldValue(key, value);
  };

  const addNewInactivityOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("inactiveStatusFee", response.name);
  };

  const inactiveStatusSchema = useMemo(() => {
    const { actions, name } = addNewExceptRulesSchema;
    return { name, ...actions.POST };
  }, [addNewExceptRulesSchema]);

  const inactivityFeeschema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const dormantStatusSchema = useMemo(() => {
    const { actions, name } = addNewExceptRulesSchema;
    return { name, ...actions.POST };
  }, [addNewExceptRulesSchema]);

  const addNewInactiveStatusOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionExceptRules,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("inactiveStatusRestrictCode", response.code);
  };

  const dormantSchema = useMemo(() => {
    const { actions, name } = addNewSchema;
    return { name, ...actions.POST };
  }, [addNewSchema]);

  const addNewDormantStatusOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionExceptRules,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("dormantStatusRestrictCode", response.code);
  };

  const addNewDormantOption = async (rjsfData: RjsfData) => {
    const response = await addNewComponent(
      addNewOptionEndpoint,
      rjsfData.formData,
      toast
    );
    if (!response) {
      throw new Error("Error");
    }
    setFieldValue("dormantStatusFee", response.name);
  };

  //check product type
  const isDepositProduct = useProductTypeCheck(
    productDetails?.ifxAcctType ?? "",
    IFX_PRODUCT_TYPE_VAL.deposit
  );

  return (
    <FormikProvider value={formik}>
      <form id="finxact-form" onSubmit={handleSubmit} noValidate>
        {isDepositProduct && (
          <CardContainer>
            <AccordionComponent
              accordionTitle={"Inactivity Protocols"}
              isExpand={0}
            >
              <Box marginTop={-6.4} className="app-form-field-container">
                <StaticText
                  textValue={
                    "Decide how periods of no activity are flagged, managed, and if any charges are applied during such periods"
                  }
                />
              </Box>
              <Box className="app-form-field-container">
                <DurationInput
                  labelName={"What is the inactive status duration?"}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("inactiveStatusDur")
                      ?.tooltip_text_main
                  }
                  value={values.inactiveStatusDur}
                  onChange={handleFormData("inactiveStatusDur")}
                  modalLinkName={"Enter code manually"}
                  modalTitle={"Enter Inactive Status Duration"}
                  modalLabelName={"What is the inactive status duration?"}
                  modalLabelTooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("inactiveStatusDur")
                      ?.tooltip_text_main
                  }
                />
                {errors.inactiveStatusDur && touched.inactiveStatusDur && (
                  <ErrorAlert>
                    <span>{errors.inactiveStatusDur as string}</span>
                  </ErrorAlert>
                )}
              </Box>

              <Box className="app-form-field-container">
                <FrequencyModule
                  value={values.inactiveStatusAlertFreq}
                  onChange={handleFormData("inactiveStatusAlertFreq")}
                  frequencyQuestionTitle="What is the inactive status alert frequency?"
                  monthDropdownLabel="On what date should the alert occur?"
                  frequencyQuestionDescription={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "inactiveStatusAlertFreq"
                    )?.tooltip_text_main
                  }
                  frequencyOutputContent="A new charge will be occur every"
                  frequencyOutputContentDesc="If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
                  monthDropdownTooltip={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "inactiveStatusAlertFreqMon"
                    )?.tooltip_text_main
                  }
                  notBusinessDayTooltip={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "inactiveStatusAlertFreqNotBusiness"
                    )?.tooltip_text_main
                  }
                  notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the alert occur?"
                />
                {errors.inactiveStatusAlertFreq &&
                touched.inactiveStatusAlertFreq ? (
                  <ErrorAlert>
                    <span>{errors.inactiveStatusAlertFreq}</span>
                  </ErrorAlert>
                ) : null}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Set an inactivity fee"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("inactiveStatusFee")
                      ?.tooltip_text_main
                  }
                  labelFor="inactivity_fee"
                />
                <CoupleRadioWithDropdown
                  id="inactivity_fee"
                  primaryOptionValue={values.inactiveStatusFee}
                  primaryRadioName={"Select an existing fee"}
                  secondaryRadioName={"Add a new fee"}
                  handlePrimaryOptionSelect={handleFormData(
                    "inactiveStatusFee"
                  )}
                  placeHolderName="Select existing fee"
                  dropdownList={feeOptions}
                  schema={inactivityFeeschema}
                  uiSchema={getFeeCalcDtlUiSchema()}
                  unRequire={feeCalcDtlUnRequire}
                  onAddNewSubmit={addNewInactivityOption}
                  modelViewUrl={API_ROUTE_CONFIGURATION.feeDetails}
                />
                {errors.inactiveStatusFee && touched.inactiveStatusFee && (
                  <ErrorAlert>
                    <span>{errors.inactiveStatusFee as string}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Set the inactive status restriction code"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "inactiveStatusRestrictCode"
                    )?.tooltip_text_main
                  }
                  labelFor="inactivity_restriction_code"
                />
                <CoupleRadioWithDropdown
                  id="inactivity_restriction_code"
                  primaryOptionValue={values.inactiveStatusRestrictCode}
                  primaryRadioName={"Select from existing restriction codes"}
                  secondaryRadioName={"Add a new restriction code"}
                  handlePrimaryOptionSelect={handleFormData(
                    "inactiveStatusRestrictCode"
                  )}
                  placeHolderName="Select existing fee"
                  dropdownList={restrictionCodes}
                  schema={inactiveStatusSchema}
                  uiSchema={exceptRuleUiSchema}
                  onAddNewSubmit={addNewInactiveStatusOption}
                  modelViewUrl={API_ROUTE_CONFIGURATION.exceptRule}
                />
                {errors.inactiveStatusRestrictCode &&
                touched.inactiveStatusRestrictCode ? (
                  <ErrorAlert>
                    <span>{errors.inactiveStatusRestrictCode}</span>
                  </ErrorAlert>
                ) : null}
              </Box>
            </AccordionComponent>
          </CardContainer>
        )}
        {/* Dormancy Management */}
        {isDepositProduct && (
          <CardContainer>
            <AccordionComponent
              accordionTitle={"Dormancy Management"}
              isExpand={0}
            >
              <Box marginTop={-6.4} className="app-form-field-container">
                <StaticText
                  textValue={
                    "Establish guidelines around alerts and fees for when an account is dormant for a specified period"
                  }
                />
              </Box>
              <Box className="app-form-field-container">
                <DurationInput
                  labelName={"What is the dormant status duration?"}
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("dormantStatusDur")
                      ?.tooltip_text_main
                  }
                  value={values.dormantStatusDur}
                  onChange={handleFormData("dormantStatusDur")}
                  modalLinkName={"Enter code manually"}
                  modalTitle={"Enter Dormant Status Duration"}
                  modalLabelName={"What is the dormant status duration?"}
                  modalLabelTooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("dormantStatusDur")
                      ?.tooltip_text_main
                  }
                />
                {errors.dormantStatusDur && touched.dormantStatusDur && (
                  <ErrorAlert>
                    <span>{errors.dormantStatusDur as string}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <FrequencyModule
                  value={values.dormantStatusAlertFreq}
                  onChange={handleFormData("dormantStatusAlertFreq")}
                  frequencyQuestionTitle="What is the dormant status alert frequency?"
                  monthDropdownLabel="On what date should the alert occur?"
                  frequencyQuestionDescription={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "dormantStatusAlertFreq"
                    )?.tooltip_text_main
                  }
                  frequencyOutputContent="A new charge will be occur every"
                  frequencyOutputContentDesc="If the date falls on a non-business day, such as a weekend or holiday, the charge will still be occur on the"
                  monthDropdownTooltip={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "dormantStatusAlertFreqMon"
                    )?.tooltip_text_main
                  }
                  notBusinessDayTooltip={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "dormantStatusAlertFreqNotBusiness"
                    )?.tooltip_text_main
                  }
                  notBusinessDayLabel="If the scheduled date falls on a non-business day, when should the alert occur?"
                />
                {errors.dormantStatusAlertFreq &&
                touched.dormantStatusAlertFreq ? (
                  <ErrorAlert>
                    <span>{errors.dormantStatusAlertFreq}</span>
                  </ErrorAlert>
                ) : null}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="Set a dormant status fee"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get("dormantStatusFee")
                      ?.tooltip_text_main
                  }
                  labelFor="dormant_fee"
                />
                <CoupleRadioWithDropdown
                  id="dormant_fee"
                  primaryOptionValue={values.dormantStatusFee}
                  primaryRadioName={"Select an existing fee"}
                  secondaryRadioName={"Add a new fee"}
                  handlePrimaryOptionSelect={handleFormData("dormantStatusFee")}
                  placeHolderName="Select existing fee"
                  dropdownList={feeOptions}
                  schema={dormantSchema}
                  uiSchema={getFeeCalcDtlUiSchema()}
                  unRequire={feeCalcDtlUnRequire}
                  onAddNewSubmit={addNewDormantOption}
                  modelViewUrl={API_ROUTE_CONFIGURATION.feeDetails}
                />
                {errors.dormantStatusFee && touched.dormantStatusFee && (
                  <ErrorAlert>
                    <span>{errors.dormantStatusFee as string}</span>
                  </ErrorAlert>
                )}
              </Box>
              <Box className="app-form-field-container">
                <AppFormLabel
                  labelName="What is the dormant status restriction code?"
                  tooltipDesc={
                    tooltipFlyoutDetails?.tooltipsMap?.get(
                      "dormantStatusRestrictCode"
                    )?.tooltip_text_main
                  }
                  labelFor="dormant_restriction"
                />
                <CoupleRadioWithDropdown
                  id="dormant_restriction"
                  primaryOptionValue={values.dormantStatusRestrictCode}
                  primaryRadioName={"Select from existing restriction codes"}
                  secondaryRadioName={"Add a new code"}
                  handlePrimaryOptionSelect={handleFormData(
                    "dormantStatusRestrictCode"
                  )}
                  placeHolderName="Select existing fee"
                  dropdownList={restrictionCodes}
                  schema={dormantStatusSchema}
                  uiSchema={exceptRuleUiSchema}
                  onAddNewSubmit={addNewDormantStatusOption}
                  modelViewUrl={API_ROUTE_CONFIGURATION.exceptRule}
                />
                {errors.dormantStatusRestrictCode &&
                  touched.dormantStatusRestrictCode && (
                    <ErrorAlert>
                      <span>{errors.dormantStatusRestrictCode as string}</span>
                    </ErrorAlert>
                  )}
              </Box>
            </AccordionComponent>
          </CardContainer>
        )}
        <CardContainer>
          <AccordionComponent
            accordionTitle={"Additional Product Details"}
            isExpand={0}
          >
            <Box className="app-form-field-container">
              <StaticText
                className="additional-description-area"
                textValue={"Define additional attributes for your product"}
              />
            </Box>
            {!isProductTypeIsLoan(productDetails) ? (
              <>
                <Box className="app-form-field-container">
                  <DurationInput
                    labelName={"What is the funding term?"}
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("fundTerm")
                        ?.tooltip_text_main
                    }
                    value={values.fundTerm}
                    onChange={handleFormData("fundTerm")}
                    modalLinkName={"Enter code manually"}
                    modalTitle={"Enter Funding Term"}
                    modalLabelName={"What is funding term?"}
                    modalLabelTooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("fundTerm")
                        ?.tooltip_text_main
                    }
                    showDashedLeftBorder
                  />
                  {errors.fundTerm && touched.fundTerm && (
                    <ErrorAlert>
                      <span>{errors.fundTerm}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="How long should an account stay at a zero balance before closing?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("zeroBalCloseDays")
                        ?.tooltip_text_main
                    }
                  />
                  <AppNumberInput
                    value={
                      values.zeroBalCloseDays ? values.zeroBalCloseDays : ""
                    }
                    onChange={handleFormData("zeroBalCloseDays")}
                  />
                  {errors.zeroBalCloseDays && touched.zeroBalCloseDays && (
                    <ErrorAlert>
                      <span>{errors.zeroBalCloseDays}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="How many days before closing should an alert be sent out?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("zeroBalAlertDays")
                        ?.tooltip_text_main
                    }
                  />
                  <AppNumberInput
                    value={
                      values.zeroBalAlertDays ? values.zeroBalAlertDays : ""
                    }
                    onChange={handleFormData("zeroBalAlertDays")}
                  />
                  {errors.zeroBalAlertDays && touched.zeroBalAlertDays && (
                    <ErrorAlert>
                      <span>{errors.zeroBalAlertDays}</span>
                    </ErrorAlert>
                  )}
                </Box>
                <Box className="app-form-field-container">
                  <AppFormLabel labelName="What is the annual percentage yield (APY)?" />
                  <AppNumberInput
                    value={values.apy}
                    onChange={handleFormData("apy")}
                    valueSuffix="%"
                    placeholder="0%"
                  />
                  {errors.apy && touched.apy && (
                    <ErrorAlert>
                      <span>{errors.apy}</span>
                    </ErrorAlert>
                  )}
                </Box>
              </>
            ) : (
              ""
            )}
            <FieldArray
              name="svcs"
              render={({ push }) => (
                <Box className="app-form-field-container">
                  <AppFormLabel
                    labelName="What are the services offered with this product?"
                    tooltipDesc={
                      tooltipFlyoutDetails?.tooltipsMap?.get("svcName")
                        ?.tooltip_text_main
                    }
                  />
                  {values.svcs?.map((service, index) => (
                    <Box key={`additionAttributeServiceBox${index}`} mb={6}>
                      <InputText
                        key={`additionAttributeServiceInput${index}`}
                        value={service.svcName}
                        onChange={handleFormData("svcName", index)}
                        onBlur={handleBlur}
                        label="What are the services offered with this product?"
                        name="svcName"
                        placeholder="Enter service name"
                      />
                    </Box>
                  ))}
                  <Button
                    mt={5}
                    className="app-btn-link"
                    onClick={() => {
                      push({
                        ...initialServiceConfig,
                      });
                    }}
                  >
                    + Add new service
                  </Button>
                </Box>
              )}
            />
          </AccordionComponent>
        </CardContainer>
      </form>
    </FormikProvider>
  );
};

export default AdditionalProductForm;
