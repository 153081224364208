"use client";

import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TableContainer,
  Text,
  Image as ChakraImg,
} from "@/components/ChakraUiManager";
import Image from "next/image";
import "./MyProducts.scss";
import {
  errorStatusImg,
  kebabIcon,
  myProductDefaultImg,
  warnningStatusImg,
} from "public/assets";
import {
  Badges,
  CardContainer,
  CustomTable,
  ToolTip,
} from "@/components/common";
import { PRODUCT_LIST_TABLE_COLUMN_REF } from "@/data/product-catalog-data";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  ProductType,
  ProductWorkflowModelType,
  filterProductsWithWorkflowStatus,
} from "../..";
import {
  ProductConfigurationContextType,
  productConfigurationContext,
} from "@/components/context-api/product-configuration-context/ProductConfigurationReducer";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  DASHBOARD_ACCORDION_STATUS,
  ENTITLEMENT_STATUS,
  EntitlementPageType,
  IFX_ACCT_PRODUCT_TYPE,
  IFX_PRODUCT_TYPE_VAL,
} from "@/utils/constants";
import { getAllWorkflowGeneric } from "@/api-config/api-service";
import { getCurrentProductFormLinkToNavigate } from "../../product-summary/product-summary-config";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { getEntitlement, getEnvId, getProj } from "@/store";
import { useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { PRODUCT_STATUS } from "@/components/data/form-data";
import { checkEntitlement, isFastProduct } from "@/utils/common";
import { ErrorAccess } from "@finxact/finxact-shared-ui";

type MyProductsProp = {
  setTabIndex?: (index: number) => void;
  productList: ProductType[];
  isPreRequisiteUnmet: boolean;
  isEnvDisabled: boolean;
  isAccleratorSupported: boolean;
};

export const myProductsColumnDef = PRODUCT_LIST_TABLE_COLUMN_REF;

function MyProducts(prop: MyProductsProp) {
  const entitlement = useSelector(getEntitlement);
  const envId = useSelector(getEnvId);
  const proj = useSelector(getProj);
  let data = {
    envId,
    entitlement: false,
    projId: proj?.id,
  };
  if (
    entitlement.pc_dep === ENTITLEMENT_STATUS.noAccess &&
    entitlement.pc_loan === ENTITLEMENT_STATUS.noAccess
  ) {
    data = {
      envId,
      entitlement: true,
      projId: proj?.id,
    };
  }
  const {
    setTabIndex,
    productList = [],
    isPreRequisiteUnmet,
    isEnvDisabled,
  } = prop;

  const [workflowProductList, setWorkflowProductList] = useState<
    ProductWorkflowModelType[]
  >([]);

  const router = useRouter();
  //context API
  const {
    setProductDetails,
    setSummaryQuickStartFlag,
    navigateWithProductNameParam,
  } = useContext<ProductConfigurationContextType>(productConfigurationContext);

  const renderDataCell = useCallback(
    (value: any, key: string, row: ProductType) => {
      switch (key) {
        case "name":
          return constructNameCell(row);
        case "status":
          return (
            <>
              {value ? (
                <Badges
                  label={getBadgeDetails(value, true)}
                  type={getBadgeDetails(value)}
                />
              ) : (
                <Box>-</Box>
              )}
            </>
          );
        case "description":
          return (
            <Box className="my-product-table-description">{value || ""}</Box>
          );
        case "action":
          return constructActionCell(row);
        default:
          return value;
      }
    },
    [productList, workflowProductList, entitlement]
  );

  const constructActionCell = (product: ProductType) => {
    const prodType = product.ifxAcctType
      ? IFX_ACCT_PRODUCT_TYPE[product.ifxAcctType]
      : "";
    if (
      (prodType === IFX_PRODUCT_TYPE_VAL.deposit &&
        entitlement.pc_dep === ENTITLEMENT_STATUS.noAccess) ||
      (prodType === IFX_PRODUCT_TYPE_VAL.loan &&
        entitlement.pc_loan === ENTITLEMENT_STATUS.noAccess)
    )
      return null;

    const LEGACY_PROD_EDIT_URL = `${ROUTE_PATH.LEGACY_EDIT_PRODUCT}/${product.name}`;

    return (
      <Box textAlign={"right"}>
        {product.status?.toLowerCase() ===
        DASHBOARD_ACCORDION_STATUS.inProgress ? (
          <Button
            className="app-btn-reg-secondary"
            onClick={() => {
              router.push(
                getCurrentProductFormLinkToNavigate(
                  product,
                  workflowProductList?.filter(
                    (item) => item?.modelKey === product?.name
                  )
                )
              );
            }}
          >
            {checkEntitlement(
              entitlement,
              prodType === IFX_PRODUCT_TYPE_VAL.loan
                ? EntitlementPageType.pc_loan
                : EntitlementPageType.pc_dep,
              [ENTITLEMENT_STATUS.readOnly]
            ) || isEnvDisabled
              ? "View"
              : "Resume"}
            <Text ml={"0.6rem"} as="span">
              {">"}
            </Text>
          </Button>
        ) : (
          <Menu>
            {product.ifxAcctType ? (
              <>
                <MenuButton
                  border={"0px"}
                  className="kebab-btn"
                  as={IconButton}
                  aria-label="Options"
                  _active={{
                    bg: "#E6F0FE",
                  }}
                  color={`var(${"--color-link-hover-color"})`}
                  icon={<Image src={kebabIcon} alt="image" />}
                  variant="outline"
                />
                <MenuList className="menu-list-container">
                  <MenuItem
                    onClick={() => {
                      if (isFastProduct(product.ifxAcctType!)) {
                        navigateToProductSummary(product, "edit");
                      } else {
                        window.location.href = `${LEGACY_PROD_EDIT_URL}/?returnTo=${encodeURIComponent(ROUTE_PATH.PRODUCT_LAUNCHPAD)}`;
                      }
                    }}
                  >
                    {checkEntitlement(
                      entitlement,
                      prodType === IFX_PRODUCT_TYPE_VAL.loan
                        ? EntitlementPageType.pc_loan
                        : EntitlementPageType.pc_dep,
                      [ENTITLEMENT_STATUS.readOnly]
                    ) || isEnvDisabled
                      ? "View"
                      : "Edit"}
                  </MenuItem>
                  {checkEntitlement(
                    entitlement,
                    prodType === IFX_PRODUCT_TYPE_VAL.loan
                      ? EntitlementPageType.pc_loan
                      : EntitlementPageType.pc_dep,
                    [ENTITLEMENT_STATUS.allAccess]
                  ) && !isEnvDisabled ? (
                    <MenuItem
                      onClick={() => {
                        navigateToProductSummary(product, "copy");
                      }}
                    >
                      Copy
                    </MenuItem>
                  ) : (
                    ""
                  )}

                  <a
                    href={`${ROUTE_PATH["EXPLORE_PRODUCT_IN_LEGACY"]}/${product.name}`}
                    target="_blank"
                    className="style-explore-link"
                  >
                    <MenuItem>Explore</MenuItem>
                  </a>
                </MenuList>
              </>
            ) : (
              <ToolTip
                content={`Oops! Unfortunately, you cannot take further action on this product unless you select the IFX Account Type. Make a selection in your original configuration <a class="app-btn-link" href=${LEGACY_PROD_EDIT_URL}>here</a>.`}
                placement={"left"}
                isViewSvgIcon={false}
                isKebabIcon={true}
                IconDefault={true}
              />
            )}
          </Menu>
        )}
      </Box>
    );
  };

  const constructNameCell = (product: any) => {
    return (
      <Flex columnGap={"1.4rem"} alignItems={"center"}>
        {product.warningCount ? (
          <Image src={warnningStatusImg} alt="warning" />
        ) : (
          ""
        )}
        {product.errorCount ? <Image src={errorStatusImg} alt="error" /> : ""}
        <Box>
          <Text>{product.name || ""}</Text>
          {product.warningCount ? (
            <Text className="warning-count">2 non-critical warning</Text>
          ) : (
            ""
          )}
          {product.errorCount ? (
            <Text className="error-count">1 cirtical error</Text>
          ) : (
            ""
          )}
        </Box>
      </Flex>
    );
  };

  function getBadgeDetails(status: string, needValue = false) {
    const statusLowerCase = status?.toLowerCase() || "";
    if (statusLowerCase === "completed") {
      return needValue ? status : "success";
    } else if (
      statusLowerCase === "in_progress" ||
      statusLowerCase === "configuration warning"
    ) {
      return needValue ? "in progress" : "warning";
    } else if (statusLowerCase === "configuration error") {
      return needValue ? status : "error";
    } else {
      return "";
    }
  }

  const productCatalogBtnLink = () => {
    return (
      <Button
        onClick={goToProductCatalog}
        mx="0.4rem"
        verticalAlign="baseline"
        className="app-btn-link"
      >
        Product Catalog
      </Button>
    );
  };

  function goToProductCatalog() {
    if (setTabIndex) {
      setTabIndex(0);
      setProductDetails?.(null);
    }
  }

  function navigateToProductSummary(product: ProductType, type: string) {
    let isQuickStartFlow = false;
    const productName = product.name;
    if (type === "copy") {
      isQuickStartFlow = true;
    }
    setProductDetails?.({ ...product });
    setSummaryQuickStartFlag?.(isQuickStartFlow);
    navigateWithProductNameParam(
      `${ROUTE_PATH["PRODUCT_SUMMARY_FULLPATH"]}`,
      productName
    );
  }

  function initMyProducts() {
    if (productList?.length) {
      getAllWorkflowGeneric(API_ROUTE_CONFIGURATION.product).then(
        (workflowProducts) => {
          setWorkflowProductList(workflowProducts as any);
        }
      );
    }
  }

  const sortTableData = (data: ProductType[]) => {
    const inProgressList = data
      .filter((pdt) => pdt.status === PRODUCT_STATUS.in_progress)
      .sort((a, b) => (a < b ? -1 : 1));
    const completedList = data
      .filter((pdt) => pdt.status !== PRODUCT_STATUS.in_progress)
      .sort((a, b) => (a < b ? -1 : 1));
    return [...inProgressList, ...completedList];
  };

  const filteredProductList = useMemo(() => {
    const filteredProduct = filterProductsWithWorkflowStatus(productList);
    return sortTableData(filteredProduct);
  }, [productList]);

  useEffect(() => {
    initMyProducts();
  }, [productList]);

  return (
    <Box className="my-product-container">
      <Box className="my-product-desc-container">
        <Heading className="my-product-desc-title" as="h3">
          Create, review and update your products!
        </Heading>
        <Flex justifyContent={"space-between"}>
          <Text>View in-flight and completed products below</Text>
          {filteredProductList.length ? (
            <Text as="p">
              <Text as="span">Navigate to the</Text>
              {productCatalogBtnLink()}
              <Text as="span">tab to start a new product</Text>
            </Text>
          ) : (
            ""
          )}
        </Flex>
      </Box>
      <CardContainer customClass="my-product-card">
        {/* Display error screen if not having entitlement to view the page */}
        {entitlement.pc_dep === ENTITLEMENT_STATUS.noAccess &&
        entitlement.pc_loan === ENTITLEMENT_STATUS.noAccess ? (
          <ErrorAccess data={data} />
        ) : filteredProductList.length && !isPreRequisiteUnmet ? (
          <TableContainer className="my-product-table" m={12}>
            <CustomTable
              data={filteredProductList}
              columns={myProductsColumnDef}
              renderDataCell={renderDataCell}
              className="my-product-table"
              isPagination={true}
              pageSizeData={20}
            />
          </TableContainer>
        ) : (
          <Flex
            className="my-product-list"
            justify="center"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={12}
          >
            <Center className="add-icon-plus">
              <ChakraImg src={myProductDefaultImg.src} alt={"product"} />
            </Center>

            <Box mb={8} textAlign="center">
              <Heading className="my-product-card-title">
                You do not have any products saved or started
              </Heading>
              {isPreRequisiteUnmet ? (
                <Text className="my-product-card-desc">
                  <Text as="span">
                    Come back after finishing your [Foundational Setup]
                  </Text>
                </Text>
              ) : (
                <Text className="my-product-card-desc">
                  <Text as="span">Click on to the</Text>
                  {productCatalogBtnLink()}
                  <Text as="span">
                    tab to get started with your Product Configuration journey!
                  </Text>
                </Text>
              )}
            </Box>
          </Flex>
        )}
      </CardContainer>
    </Box>
  );
}

export default MyProducts;
