import { PostSchema } from "@/components/product-management/model/types";
import { generateYupSchema } from "@/utils/common";
import * as Yup from "yup";

//Defining Interface For Limit_Account_Balance_Configuration
interface AdditionalProductFormProps {
  fundTerm: string;
  zeroBalCloseDays: string;
  zeroBalAlertDays: string;
  apy: string;
  svcs: {
    svcName?: string;
  }[];
  inactiveStatusAlertFreq: string;
  inactiveStatusDur: string;
  inactiveStatusFee: string;
  inactiveStatusRestrictCode: string;
  dormantStatusAlertFreq: string;
  dormantStatusDur: string;
  dormantStatusFee: string;
  dormantStatusRestrictCode: string;
}

const additionalProductsConfigurationSchema = async (
  optionsSchema: PostSchema
) => {
  const fields = [
    "fundTerm",
    "zeroBalAlertDays",
    "zeroBalCloseDays",
    "inactiveStatusAlertFreq",
    "inactiveStatusDur",
    "inactiveStatusFee",
    "inactiveStatusRestrictCode",
    "dormantStatusAlertFreq",
    "dormantStatusDur",
    "dormantStatusFee",
    "dormantStatusRestrictCode",
  ];
  const shapeConfig = await generateYupSchema(fields, optionsSchema);
  return Yup.object().shape(shapeConfig);
};

export type { AdditionalProductFormProps };
export { additionalProductsConfigurationSchema as getValidationSchema };
