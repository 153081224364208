import {
  addBookmark,
  deleteBookmark,
} from "@/client-api-manager/alm-api";
import ActionButton from "@/components/academy/certification-path/action-button/ActionButton";
import styleVars from "@/styles/_export.module.scss";
import { safelyFetchDomain } from "@/utils/common";
import { ChevronRightIcon, StarIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Text } from "@chakra-ui/react";
import { usePathname, useSearchParams } from "next/navigation";
import { bookmarkIcon, bookmarkedIcon } from "public/assets";
import { useState, useEffect } from "react";
import "./BadgeStatus.scss";

type BadgeStatusProps = {
  id: string;
  enrollmentId: string;
  bookmark: boolean | undefined;
  totalCourse: number | undefined;
  completedCourse: number | undefined;
  shouldShowCompletion: boolean;
  type: string;
  title: string;
};

export default function BadgeStatus({
  id,
  enrollmentId,
  bookmark,
  totalCourse,
  completedCourse,
  shouldShowCompletion,
  type,
  title,
}: BadgeStatusProps) {
  const [bookmarkState, toggleBookmarkState] = useState<Boolean>(
    bookmark ?? false
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const routePathName = usePathname();
  const routeSearchParams = useSearchParams();
  const domain = safelyFetchDomain();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        window.location.reload(); 
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const bookmarkProgram = () => {
    bookmarkState ? deleteBookmark(id) : addBookmark(id);
    toggleBookmarkState((prevState) => !prevState);
  };

  return (
    <Box
      className={`badge-status-container ${shouldShowCompletion ? "" : "reduced-height"}`}
    >
      <Box className="badge-status-completion">
        {shouldShowCompletion && (
          <>
            <Text as="h5" className="completion-number">
              {completedCourse}/{totalCourse}
              <Text as="h6" className="completion-text">
                Trainings complete
              </Text>
            </Text>
            <Divider className="completion-divider" />
          </>
        )}
        <Box className="action-button-holder">
          <ActionButton
            name={bookmarkState ? "Saved" : "Save"}
            icon={bookmarkState ? bookmarkedIcon : bookmarkIcon}
            onClick={bookmarkProgram}
            isSrcIcon={true}
          />
        </Box>
      </Box>

    </Box>
  );
}
