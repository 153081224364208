// SavedByMeProfile.tsx

import React, { useCallback } from "react";
import CourseProfile from "../course-profile/CourseProfile";
import { getRecommendedCourses } from "@/client-api-manager/alm-api";
import { formatDuration } from "@/utils/common";
import { formatDate } from "../../learning-util-service";
import { courseDefault } from "public/assets";
import { HeaderData } from "@/models/academy-models";
import { CourseDetail } from "@/utils/types";

const SavedCourses = ({
  headerData,
  reload,
  setReload,
}: {
  headerData: HeaderData;
  reload: boolean;
  setReload: (val: boolean) => void;
}) => {
  const fetchData = useCallback(async (): Promise<CourseDetail[]> => {
    const response = await getRecommendedCourses({
      includeBookmarks: true,
      limit: 10,
      sort: "-recommendationScore",
    });
    if (!response || !Array.isArray(response)) {
      throw new Error("Invalid response from SavedCourses");
    }

    return response.map((course) => ({
      id: course.id,
      authorNames: course.attributes.authorNames,
      bannerUrl: course.attributes.bannerUrl,
      imageUrl: course.attributes.imageUrl ?? courseDefault.src,
      name: course.attributes.localizedMetadata?.[0]?.name,
      description: course.attributes.localizedMetadata?.[0]?.description,
      date: formatDate(course.attributes.effectiveModifiedDate, "MM/DD/YY"),
      timeline: formatDuration(course.attributes.duration, true),
      isBookmarked: course.attributes.isBookmarked,
      loType: course.attributes.loType,
      loFormat: course.attributes.loFormat,
      isEnrolled: !!course.relationships?.enrollment,
      enrollmentId: course.relationships?.enrollment?.data?.id || "",
      relationships: course.relationships,
    }));
  }, []);

  return (
    <CourseProfile
      fetchData={fetchData}
      title={headerData?.headline || "Saved by me"}
      subtitle={
        headerData?.subhead ||
        "Acquire key badges to qualify for certification tests and propel your financial career forward"
      }
      viewAll={true}
      noResultsFoundText={"There are no saved courses"}
      reload={reload}
      setReload={setReload}
    />
  );
};

export default SavedCourses;
