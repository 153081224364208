// CourseProfileInfoCard.tsx

import PropTypes from "prop-types";
import React from "react";
import { StyleTwoTone } from "../icons/StyleTwoTone/StyleTwoTone";
import { FastInformationTag, Frame } from "@/components";
import "./CourseProfileInfoCard.scss";
import { IconButton, MenuButton } from "@chakra-ui/react";
import { bookmarkedIcon, bookmarkIcon } from "public/assets";
import { Menu, MenuList, MenuItem, Image } from "@/components/ChakraUiManager";
import { StarIcon } from "@chakra-ui/icons";
import styleVars from "@/styles/_export.module.scss";
import Link from "next/link";
import { ROUTE_PATH } from "@/route-config/route-path";


interface Props {
  showImage?: boolean;
  imageUrl?: string;
  showInfo?: boolean;
  authorNames?: string;
  timeline?: string;
  date?: string;
  showDescription?: boolean;
  description?: string;
  showLabel?: boolean;
  label?: string;
  showEllipsis?: boolean;
  showTag?: boolean;
  showTitle?: boolean;
  title?: string;
  courseId: string;
  isBookmarked: boolean;
  progressPercent: number;
  toggleBookmark: (courseId: string) => void;
}

export const CourseProfileInfoCard: React.FC<Props> = ({
  showImage = true,
  imageUrl = "",
  showInfo = true,
  authorNames = "",
  timeline = "",
  date = "",
  showDescription = true,
  description = "",
  showLabel = true,
  label = "Course",
  showEllipsis = true,
  showTag = true,
  showTitle = true,
  title = "Course Title",
  isBookmarked = false,
  progressPercent = 0,
  toggleBookmark,
  courseId,
}) => {
  const handleBookmarkClick = () => {
    toggleBookmark(courseId);
  };

  const getLabel = () => {
    switch (label) {
      case "course":
        return "Course";
      case "learningProgram":
        return "Badge path";
      case "certification":
        return "Certification";
    }
  };

  const getURL = () => {
    switch (label) {
      case "course":
        return ROUTE_PATH.ACADEMY_CATALOG_COURSE;
      case "learningProgram":
        return ROUTE_PATH.ACADEMY_CATALOG_BADGE_PATH;
      case "certification":
        return ROUTE_PATH.ACADEMY_CERTIFICATION_PATH;
    }
  };

  return (
    <div className="wide-content-card">
      <div className="div">
        <div className="frame-2">
          {showImage && (
            <div
              className="content-card"
              style={{ 
                backgroundImage: `url(${imageUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",      
              }}
            >
              <div className="rectangle" />
            </div>
          )}
          <div className="frame-3">
            <div className="frame-4">
              <div className="frame-5">
                {showTitle && (
                  <Link
                    href={`${getURL()}?id=${courseId}&from=my-learning`}
                    className="title"
                  >
                    {title}
                  </Link>
                )}
                {showTag && (
                  <FastInformationTag progressPercent={progressPercent} />
                )}
              </div>
              {showLabel && <div className="text-wrapper">{getLabel()}</div>}
              <div className="frame-6">
                {showDescription && <p className="p">{description}</p>}
                {showInfo && (
                  <div className="frame-7">
                    {authorNames && (
                      <Frame
                        className="frame-1000005836"
                        text={`By: ${authorNames}`}
                      />
                    )}
                    <Frame className="frame-1000005836" text={timeline} />
                    <Frame
                      className="frame-1000005836"
                      showDivider={false}
                      text={`Last visited on ${date}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Menu>
          <MenuButton
            border={"0px"}
            className="ellipsis-button"
            as={IconButton}
            aria-label="Options"
            icon={<StyleTwoTone className="ellipsis-icon" />}
            variant="outline"
          />
          <MenuList className="menu-list-container academy-menu-list">
            <MenuItem
              className="dropdown-menu-item"
              onClick={handleBookmarkClick}
            >
              <Image
                src={isBookmarked ? bookmarkedIcon.src : bookmarkIcon.src}
                alt="Bookmark"
                className="menu-image"
              />
              <span className="menu-text">
                {isBookmarked ? "Saved" : "Save"}
              </span>
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

CourseProfileInfoCard.propTypes = {
  showImage: PropTypes.bool,
  imageUrl: PropTypes.string,
  showInfo: PropTypes.bool,
  authorNames: PropTypes.string,
  timeline: PropTypes.string,
  date: PropTypes.string,
  showDescription: PropTypes.bool,
  description: PropTypes.string,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  showEllipsis: PropTypes.bool,
  showTag: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.string,
  courseId: PropTypes.string.isRequired,
};

export default CourseProfileInfoCard;
