"use client";

import { useEffect, useState } from "react";
import { MultiTieredSideMenuDataModel } from "../common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  getAppConfig,
  getEnv,
  getEnvName,
  getEnvStatus,
  getSelectedOrg,
  setAllowConfigChangeState,
  setEnvId,
  setEnvStatus,
  setEnvType,
  setOrgId,
} from "@/store";
import { usePathname } from "next/navigation";
import { Menu } from "@finxact/finxact-shared-ui";
import { deepCopy, useIsPermitted } from "@/utils/common";
import Link from "next/link";
import { getProj } from "@/store/slices/project";
import {
  getAllEnvs,
  getAllProjs,
  getOrgsAsArray,
} from "@/store/slices/organization";
import "../../styles/color-variables.scss";
import localforage from "localforage";
import {
  ENV_ID_KEY,
  ENV_TYPE_KEY,
  ORGANIZATION_COOKIE,
} from "@/utils/constants";
import { setCookie } from "cookies-next";

const GlobalSideMenu = () => {
  const pathName: any = usePathname()?.replace(/\/$/, "") ?? null;
  const isPermitted = useIsPermitted();
  const appConfig = useSelector(getAppConfig);
  const organization = useSelector(getSelectedOrg);
  const orgs = useSelector(getOrgsAsArray);
  const { envId, envType, imageTag, version, endpoint, internal } =
    useSelector(getEnv);
  const project = useSelector(getProj);
  const envName = useSelector(getEnvName);
  const envs = useSelector(getAllEnvs);
  const projs = useSelector(getAllProjs);
  const envStatus = useSelector(getEnvStatus);
  const [envData, setEnvData] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const currentEnv = envStatus.find((env) => env.id === envId);
    const sliceVerIndex = version ? version.indexOf("-") : -1;
    const minifiedVersion =
      sliceVerIndex >= 0 ? version?.substring(0, sliceVerIndex) : null;
    setEnvData(
      deepCopy({
        name: envName,
        version: minifiedVersion,
        serviceEndpoint: currentEnv?.serviceEndpoint ?? "",
      })
    );
  }, [envStatus, envId, envName, version]);

  const handleSelection = (
    locData: MultiTieredSideMenuDataModel[],
    selectedUID: string | null
  ) => {
    locData.map((item: MultiTieredSideMenuDataModel) => {
      if (item.uid === selectedUID) {
        item.isSelected = true;
      } else {
        handleSelection(item.childrens, selectedUID);
        item.isSelected = item.childrens.filter((i) => i.isSelected).length > 0;
      }
    });
    return locData;
  };

  const env =
    envType === "env" && envId && envName
      ? {
          id: envId,
          name: envName,
          endpoint,
          internal,
        }
      : undefined;

  const proj =
    envType === "proj" && envId && envName
      ? {
          id: envId,
          name: envName,
          status: project?.status,
        }
      : undefined;

  const removeEnv = async () => {
    await localforage.removeItem(ENV_ID_KEY, undefined);
    await localforage.removeItem(ENV_TYPE_KEY, undefined);
    dispatch(setEnvType(null));
    dispatch(setEnvId(null));
    dispatch(setEnvStatus([]));
  };

  const onOrgItemSelect = (id: number, envType: "env" | "proj") => {
    Promise.all([
      localforage.setItem(ENV_ID_KEY, id),
      localforage.setItem(ENV_TYPE_KEY, envType),
    ]).then(() => {
      dispatch(setEnvType(envType));
      dispatch(setEnvId(id));
    });
  };

  const onOrgSelect = (orgId: number) => {
    setCookie(ORGANIZATION_COOKIE, `${orgId}`);
    dispatch(setOrgId(orgId));
    removeEnv();
    // reset allowConfigChanges when org changes
    dispatch(setAllowConfigChangeState(null));
  };

  const onProjSelect = (projId: number) => {
    onOrgItemSelect(projId, "proj");
  };

  const onEnvSelect = (envId: number) => {
    onOrgItemSelect(envId, "env");
  };

  return (
    <Menu
      consoleConfig={{
        prodEnv: appConfig?.prodEnv ?? false,
        releaseCalendarConfigured:
          appConfig?.releaseCalendarConfigured ?? false,
      }}
      coreVersion={envData?.version}
      environment={env}
      envs={envs}
      imageTag={imageTag ?? undefined}
      organization={{
        id: organization.id,
        name: organization.name,
        implRepoURL: organization.implRepoURL,
        serviceTicketURL: organization.serviceTicketURL,
        implementationUrl: organization.implementationUrl,
        status: organization.status,
        category: organization.category,
      }}
      orgs={orgs}
      onEnvSelect={onEnvSelect}
      onOrgSelect={onOrgSelect}
      onProjSelect={onProjSelect}
      isPermitted={isPermitted}
      location={pathName}
      parent="fast"
      project={proj}
      projs={projs}
      serviceEndpoint={envData?.serviceEndpoint}
      nextRouterLinkComponent={Link}
    />
  );
};

export default GlobalSideMenu;
